import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import moment from 'moment';
import { add } from '../../features/referenceBook/referenceBookSlice';
import {
  addFormData,
  addJob,
  addClient,
  addWorkHistory,
  setDatesToConfirm,
  setAdHocDates,
  setIsConfirmed, setNewNotes, setNewFiles, setLevyEnabled, setLevyValue, setEditedId, saveDatesForBooking,
} from '../../features/bookingForm/bookingFormSlice';
import api from '../../config/api';
import ApplicantSection from '../../components/ApplicantSection/ApplicantSection';
import JobSection from '../../components/JobSection/JobSection';
import ClientSection from '../../components/ClientSection/ClientSection';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import {
  saveAsDraftSuccess,
  createSuccess,
  emailSendSuccess, error,
} from '../../helpers/notyf';
import css from './BookingFormPage.module.css';
import SendEmailModal from '../../components/JobSection/SendEmailModal/SendEmailModal';

const props = {
  color: 'var(--primary)',
  height: 100,
  width: 110,
  secondaryColor: 'var(--primary)',
};

const BookingFormPage = () => {
  const [id, setId] = useState('');
  const [jobId, setJobId] = useState('');
  const [previousJob, setPreviousJob] = useState();
  const [clientId, setClientId] = useState('');
  const [loading, setLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [checked, setChecked] = useState({ applicant: false, client: false });
  const [emailData, setEmailData] = useState({});
  const [user, setUser] = useState({});
  const [isAvailability, setIsAvailability] = useState(false);

  const formData = useSelector(state => state.bookingForm.formData);
  const files = useSelector(state => state.bookingForm.files);
  const notes = useSelector(state => state.bookingForm.notes);
  const dates = useSelector(state => state.bookingForm.formData?.job?.booked_data?.dates);
  const pay_rates = useSelector(state => state.bookingForm.formData?.job?.booked_data?.pay_rates);
  const workHistory = useSelector(state => state.bookingForm.workHistory);
  const levyEnabled = useSelector(state => state.bookingForm.levyEnabled);
  const levyValue = useSelector(state => state.bookingForm.levyValue);
  const job = useSelector(state => state.bookingForm.formData.job);


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if(params.external_id){
      setId(params.external_id);
      setIsAvailability(true);
      handleApplicantById(params.external_id);
    }
  }, [params.external_id]);

  useEffect(() => {
    localStorage.getItem('ACCESS_TOKEN') &&
    api.AUTH_ME().then(response => setUser(response));
  }, []);

  useEffect(() => {
    if (!params.id && !params.external_id) {
      dispatch(addFormData({}));
      dispatch(addWorkHistory([]));
      dispatch(setDatesToConfirm([]));
      dispatch(setAdHocDates([]));
      dispatch(setIsConfirmed(false));
      dispatch(setNewNotes(''));
      dispatch(setNewFiles([]));
      dispatch(setLevyEnabled(true));
      dispatch(setLevyValue(1.138));
      setId('');

      return;
    }
    if (params.id) {
      setLoading(true);
      api
        .SHOW_FORM(params.id)
        .then(response => {
          if (response?.status && response.status === 'OK') {
            dispatch(addFormData(response.data));
            const { files, notes, booked_data } = response?.data?.job ?? {};
            const { enabled, value } = booked_data.levy ?? {};
            if(booked_data?.dates.length === 0) {
                dispatch(setAdHocDates([]));
            }
            dispatch(setNewNotes(notes || ''));
            dispatch(setNewFiles(files));
            dispatch(setLevyEnabled(enabled));
            dispatch(setLevyValue(value || 1.138));
            setId(response.data.applicant.external_id);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [params.id]);

  useEffect(() => {
    dispatch(setEditedId(params.id));
    const handleBeforeUnload = async (event) => {
      event.preventDefault();
      if (params.id) {
        await api.CHANGE_EDITING(params.id);
        dispatch(setEditedId(null));
      }
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    api.GET_REF_BOOK().then(response => dispatch(add(response)));

    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = e => {
    e.preventDefault();
    e.returnValue =
      'Are you sure you want to leave this page? All unsave changes will be lost!';
  };

  const handleApplicantIdChange = e => {
    if (e.target.value.length > 6 || isNaN(+e.target.value)) return;
    setId(+e.target.value);
  };

  const datesAvailability = useSelector(
    state => state.bookingForm.datesForBooking,
  );

  const handleApplicantById = (external_id) => {
    setLoading(true);
    dispatch(addFormData({}));
    dispatch(addWorkHistory([]));
    dispatch(setDatesToConfirm([]));
    dispatch(setAdHocDates([]));
    dispatch(setIsConfirmed(false));
    setClientId('');
    setJobId('');
    api
      .GET_APPLICANT(id || external_id)
      .then(applicant => {
        if (applicant?.status === 'OK') {
          if (external_id) {
            if (applicant.data && applicant.data.job && applicant.data.job.booked_data) {
              const { minStartTime, maxEndTime } = datesAvailability.reduce((acc, item) => {
                if (!acc.minStartTime || new Date(item.start_time) < new Date(acc.minStartTime)) {
                  acc.minStartTime = item.start_time;
                }
                if (!acc.maxEndTime || new Date(item.end_time) > new Date(acc.maxEndTime)) {
                  acc.maxEndTime = item.end_time;
                }
                return acc;
              }, { minStartTime: null, maxEndTime: null });
              applicant.data.job.booked_data.dates = datesAvailability;
              applicant.data.job.end_date = maxEndTime;
              applicant.data.job.start_date = minStartTime;
              applicant.data.job.booked_data.type = "ad_hoc";
            }
          }
          dispatch(addFormData(applicant.data));
          api.GET_WORK_HISTORY(id || external_id).then(response => {
            if (response?.status && response.status === 'OK') {
              if (!response.data) {
                return;
              }
              dispatch(addWorkHistory(response.data));
              api
                .SET_AND_GET_DATA_BY_PLACEMENT_ID(
                  { ...applicant.data },
                  response.data[0].placement_external_id,
                )
                .then(response => {
                  if (response?.status && response.status === 'OK') {
                    if (external_id) {
                      if (applicant.data && applicant.data.job && applicant.data.job.booked_data) {
                        applicant.data.job.booked_data.dates = datesAvailability;
                        applicant.data.job.booked_data.type = "ad_hoc";
                        dispatch(saveDatesForBooking([]));
                      }
                    }
                    dispatch(addFormData(response.data));
                  }
                });
            }
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const handleJobChange = e => {
    setLoading(true);
    setPreviousJob(+e.target.value);
    api
      .SET_AND_GET_DATA_BY_PLACEMENT_ID({ ...formData }, +e.target.value)
      .then(response => {
        if (response?.status && response.status === 'OK') {
          dispatch(addJob(response.data.job));
          dispatch(addClient(response.data.client));
        }
      })
      .finally(() => setLoading(false));
  };

  const handleIdInput = e => {
    if (e.target.value.length > 6 || isNaN(+e.target.value)) return;
    if (e.target.name === 'job') {
      setJobId(+e.target.value);
      setClientId('');
      return;
    }

    setClientId(+e.target.value);
    setJobId('');
  };

  const handleJobData = () => {
    if (jobId) {
      setLoading(true);
      api
        .SET_AND_GET_DATA_BY_JOB_ID({ ...formData }, jobId)
        .then(response => {
          if (response?.status && response.status === 'OK') {
            dispatch(addJob(response.data.job));
            dispatch(addClient(response.data.client));
          }
        })
        .finally(() => setLoading(false));
    }

    if (clientId) {
      setLoading(true);
      api
        .SET_AND_GET_DATA_BY_CLIENT_ID({ ...formData }, clientId)
        .then(response => {
          if (response?.status && response.status === 'OK') {
            dispatch(addClient(response.data.client));
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const getParsedHours = value => {
    if (String(value)?.includes(':')) {
      const [hours, minutes] = value.split(':');

      return +Number(
        +hours + (minutes == '0' || minutes == '00' ? 0 : +minutes / 60),
      ).toFixed(2);
    } else return +value;
  };

  const handleSaveDraft = async () => {
    setLoading(true);

    const day_shifts = formData.job.booked_data.day_shifts.map(el => {
      const { start_time, end_time } = el;
      if (!start_time && !end_time) return el;

      return {
        ...el,
        start:
          typeof start_time === 'string'
            ? start_time
            : new Date(
              new Date(start_time).getTime() -
              new Date(start_time).getTimezoneOffset() * 60000,
            ),
        end:
          typeof end_time === 'string'
            ? end_time
            : new Date(
              new Date(end_time).getTime() -
              new Date(end_time).getTimezoneOffset() * 60000,
            ),
        start_time:
          typeof start_time === 'string'
            ? start_time
            : new Date(
              new Date(start_time).getTime() -
              new Date(start_time).getTimezoneOffset() * 60000,
            ),
        end_time:
          typeof end_time === 'string'
            ? end_time
            : new Date(
              new Date(end_time).getTime() -
              new Date(end_time).getTimezoneOffset() * 60000,
            ),
      };
    });

    const booked_dates = formData.job.booked_data.dates.map(el => {
      const { start, end, date } = el;
      if (!start && !end && !date) return el;

      return {
        ...el,
        date: new Date(
          new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000,
        ),
        ...(formData.job.booked_data.type === 'standard'
          ? {
            start_time:
              typeof start === 'string'
                ? start
                : new Date(
                  new Date(start).getTime() -
                  new Date(start).getTimezoneOffset() * 60000,
                ),
            end_time:
              typeof end === 'string'
                ? end
                : new Date(
                  new Date(end).getTime() -
                  new Date(end).getTimezoneOffset() * 60000,
                ),
          }
          : {
            start_time:
              typeof el?.start_time === 'string'
                ? el?.start_time
                : new Date(
                  new Date(el?.start_time).getTime() -
                  new Date(el?.start_time).getTimezoneOffset() * 60000,
                ),
            end_time:
              typeof el?.end_time === 'string'
                ? el?.end_time
                : new Date(
                  new Date(el?.end_time).getTime() -
                  new Date(el?.end_time).getTimezoneOffset() * 60000,
                ),
          }),
        start:
          typeof start === 'string'
            ? start
            : new Date(
              new Date(start).getTime() -
              new Date(start).getTimezoneOffset() * 60000,
            ),
        end:
          typeof end === 'string'
            ? end
            : new Date(
              new Date(end).getTime() -
              new Date(end).getTimezoneOffset() * 60000,
            ),
        hours:
          typeof el.hours === 'number' ? el.hours : getParsedHours(el.hours),
      };
    });
    if (params.id) {
      await api.CHANGE_EDITING(params.id);
      dispatch(setEditedId(null));
    }

    api
      .SAVE_FORM_AS_DRAFT({
        ...formData,
        job: {
          ...formData.job,
          start_date: new Date(
            moment(formData.job.start_date)
              .set('h', 0)
              .set('m', 0)
              .set('s', 0)
              .toDate()
              .getTime() -
            new Date(formData.job.start_date).getTimezoneOffset() * 60000,
          ),
          end_date: new Date(
            moment(formData.job.end_date)
              .set('h', 0)
              .set('m', 0)
              .set('s', 0)
              .toDate()
              .getTime() -
            new Date(formData.job.end_date).getTimezoneOffset() * 60000,
          ),
          booked_data: {
            ...formData.job.booked_data,
            day_shifts,
            dates: booked_dates,
            levy: {
              enabled: levyEnabled,
              value: levyValue,
            },
          },
          files: files,
          notes: '' + notes,
        },
      })
      .then(status => {
        status === 'OK' &&
        saveAsDraftSuccess() &&
        navigate('/list-of-forms', { replace: true });
      })
      .finally(() => setLoading(false));
  };

  const handleCreatePlacement = async () => {
    setLoading(true);
    let newRates = [...pay_rates];

    newRates = newRates.map(rate => {
      if (
        dates.find(
          shift => +shift.shift_external_id === +rate.shift_external_id,
        )
      ) {
        return rate;
      }

      return { ...rate, hidden: true };
    });

    newRates = newRates.filter(rate => {
      if (!rate.new) {
        return rate;
      } else if (rate.hidden) {
        return false;
      }

      return rate;
    });

    const day_shifts = formData.job.booked_data.day_shifts.map(el => {
      const { start_time, end_time } = el;
      if (!start_time && !end_time) return el;

      return {
        ...el,
        start:
          typeof start_time === 'string'
            ? start_time
            : new Date(
              new Date(start_time).getTime() -
              new Date(start_time).getTimezoneOffset() * 60000,
            ),
        end:
          typeof end_time === 'string'
            ? end_time
            : new Date(
              new Date(end_time).getTime() -
              new Date(end_time).getTimezoneOffset() * 60000,
            ),
        start_time:
          typeof start_time === 'string'
            ? start_time
            : new Date(
              new Date(start_time).getTime() -
              new Date(start_time).getTimezoneOffset() * 60000,
            ),
        end_time:
          typeof end_time === 'string'
            ? end_time
            : new Date(
              new Date(end_time).getTime() -
              new Date(end_time).getTimezoneOffset() * 60000,
            ),
      };
    });

    const booked_dates = formData.job.booked_data.dates.map(el => {
      const { start, end, date } = el;
      if (!start && !end && !date) return el;

      return {
        ...el,
        date: new Date(
          new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000,
        ),
        ...(formData.job.booked_data.type === 'standard'
          ? {
            start_time:
              typeof start === 'string'
                ? start
                : new Date(
                  new Date(start).getTime() -
                  new Date(start).getTimezoneOffset() * 60000,
                ),
            end_time:
              typeof end === 'string'
                ? end
                : new Date(
                  new Date(end).getTime() -
                  new Date(end).getTimezoneOffset() * 60000,
                ),
          }
          : {
            start_time:
              typeof el?.start_time === 'string'
                ? el?.start_time
                : new Date(
                  new Date(el?.start_time).getTime() -
                  new Date(el?.start_time).getTimezoneOffset() * 60000,
                ),
            end_time:
              typeof el?.end_time === 'string'
                ? el?.end_time
                : new Date(
                  new Date(el?.end_time).getTime() -
                  new Date(el?.end_time).getTimezoneOffset() * 60000,
                ),
          }),
        start:
          typeof start === 'string'
            ? start
            : new Date(
              new Date(start).getTime() -
              new Date(start).getTimezoneOffset() * 60000,
            ),
        end:
          typeof end === 'string'
            ? end
            : new Date(
              new Date(end).getTime() -
              new Date(end).getTimezoneOffset() * 60000,
            ),
        hours:
          typeof el.hours === 'number' ? el.hours : getParsedHours(el.hours),
      };
    });
    if (params.id) {
      await api.CHANGE_EDITING(params.id);
      dispatch(setEditedId(null));
    }
    api
      .CREATE_PLACEMENT({
        ...formData,
        job: {
          ...formData.job,
          start_date: new Date(
            moment(formData.job.start_date)
              .set('h', 0)
              .set('m', 0)
              .set('s', 0)
              .toDate()
              .getTime() -
            new Date(formData.job.start_date).getTimezoneOffset() * 60000,
          ),
          end_date: new Date(
            moment(formData.job.end_date)
              .set('h', 0)
              .set('m', 0)
              .set('s', 0)
              .toDate()
              .getTime() -
            new Date(formData.job.end_date).getTimezoneOffset() * 60000,
          ),
          booked_data: {
            ...formData.job.booked_data,
            day_shifts,
            dates: booked_dates,
            levy: {
              enabled: levyEnabled,
              value: levyValue,
            },
          },
        },
      })
      .then(status => {
        status === 'OK' &&
        createSuccess() &&
        navigate('/list-of-forms', { replace: true });
      })
      .finally(() => setLoading(false));
  };

  const handleGeneratePDF = (only) => {
    api.GET_DOCUMENTS(formData.id, only).then(
      res =>
        res.status === 'OK' &&
        res.data.map(doc =>
          api
            .DOWNLOAD_DOCUMENT(formData.id, { filename: doc.name })
            .then(response => {
              const url = window.URL.createObjectURL(
                new Blob([response], {
                  type: 'application/pdf;charset=utf-8',
                }),
              );
              const link = document.createElement('a');
              link.href = url;
              link.download = doc.download_name;
              document.body.appendChild(link);
              link.click();
              window.URL.revokeObjectURL(url);
              link.remove();
            }),
        ),
    );
  };

  const handleEnterApplicant = e => e.key === 'Enter' && handleApplicantById();

  const handleEnterJobData = e => e.key === 'Enter' && handleJobData();

  const handleCheck = e =>
    setChecked({ ...checked, [e.target.name]: e.target.checked });

  const handleSendEmail = (data) => {
    const { recipient, subject, emails, cc_emails, bcc_emails, body, filesSend } = data;
    return api
      .SEND_EMAIL(formData.id, { recipient, subject, emails, cc_emails, bcc_emails, body, files: filesSend })
      .then(res => res === 'OK' && emailSendSuccess([recipient]))
      .catch(err => error(err));
  };

  const handleSendEmailModal = () => {
    api.GET_EMAIL_DATA(formData.id).then((data) => {
      setEmailData(data?.data ?? {});
    });
    setShowEmailModal(!showEmailModal);

  };

  return (
    <main className={css.main}>
      {(formData.edited_by && formData.edited_by !== user.id && !loading) &&
        <div className={css.headerNotificationContainer}>
          <div className={css.headerNotification}>
            <p className={css.headerNotificationText}>This form is currently being edited by
              the {formData.edited_by_user}</p>
          </div>
        </div>
      }
      <header>
        <div className={css.flex1}>
          <div>
            <div className={css.inputHeader}>
              <Input
                type='text'
                placeholder='Enter Applicant ID'
                onChange={handleApplicantIdChange}
                onKeyDown={handleEnterApplicant}
                value={id}
                autoFocus
                readOnly={formData.status === 'archive'}
                required
              />
            </div>
            {!formData.placement_external_id &&
              <div className={css.btnContainer}>
                <Button
                  type='secondary'
                  title='Get applicant data'
                  onClick={handleApplicantById}
                />
              </div>
            }
          </div>
          {workHistory.length === 0 && (
            <Select
              size='x33'
              value={previousJob || workHistory[0]?.placement_external_id}
              options={
                workHistory.length > 0 &&
                workHistory.map(el => {
                  return {
                    key: el.placement_external_id,
                    title: el.full_description,
                  };
                })
              }
              defaultValue='Previous Applicant Job'
              onChange={handleJobChange}
              disabled={formData.status === 'archive'}
            />
          )}
          {(!formData?.status ||
            formData?.status === 'draft' ||
            formData?.status === 'error') && (
            <Button
              type={(!formData?.applicant?.external_id || !job.is_agree) ? 'disabled2' : 'primary'}
              title='Save as draft'
              onClick={handleSaveDraft}
              disabled={!formData?.applicant?.external_id || !job.is_agree}
            />
          )}
        </div>
        {workHistory.length > 0 && (
          <Select
            size='x70'
            title={true}
            value={previousJob || workHistory[0]?.placement_external_id}
            options={
              workHistory.length > 0 &&
              workHistory.map(el => {
                return {
                  key: el.placement_external_id,
                  title: el.full_description,
                };
              })
            }
            defaultValue='Previous Applicant Job'
            onChange={handleJobChange}
            disabled={formData.status === 'archive'}
          />
        )}
        {loading && (
          <div className={css.loader}>
            <Oval {...props} />
          </div>
        )}
        {formData?.applicant?.external_id && !loading && (
          <div className={css.flex2}>
            <div>
              <div className={css.inputHeader}>
                <Input
                  value={jobId || ''}
                  name='job'
                  type='text'
                  placeholder='Job ID'
                  onChange={handleIdInput}
                  onKeyDown={handleEnterJobData}
                  readOnly={formData.status === 'archive'}
                />
              </div>
              <Input
                value={clientId || ''}
                type='text'
                placeholder='Client ID'
                onChange={handleIdInput}
                onKeyDown={handleEnterJobData}
                readOnly={formData.status === 'archive'}
              />
            </div>
            {!formData.placement_external_id ?
              <Button
                type='secondary'
                title='Get job data'
                onClick={handleJobData}
              />
              :
              <Button
                type={'secondary'}
                style={{ minWidth: 170 }}
                onClick={handleSendEmailModal}>
                Send e-mail
              </Button>
            }
          </div>
        )}
      </header>
      {formData?.applicant?.external_id && !loading && (
        <main className={css.mainForm}>
          <div className={css.select}>
            {formData.applicant.consultants.map((el, i) => (
              <Input
                readOnly
                key={el.external_id}
                value={`${el.first_name} ${el.last_name}`}
                size='x50'
                type='text'
                placeholder={`${i + 1}${i === 0 ? 'st' : 'nd'} RC Full Name`}
                required
              />
            ))}
          </div>
          <ApplicantSection />
          <JobSection id={params.id} isAvailability={isAvailability}/>
          <ClientSection />
          {(!formData?.status ||
            formData?.status === 'draft' ||
            formData?.status === 'error') && (
            <div className={css.hr}>
              <Button
                disabled={!job.is_agree}
                type={!job.is_agree ? 'primaryBigDisabled' : 'primaryBig'}
                title='Create Placement'
                onClick={handleCreatePlacement}
              />
            </div>
          )}
          {formData.placement_external_id && (
            <>
              <div className={css.flex3}>
                <Input
                  readOnly
                  value={formData.completed_by_user}
                  size='x50'
                  type='text'
                  placeholder='Completed By'
                />
                <Input
                  readOnly
                  value={moment(formData.created_on).format('DD/MM/YYYY HH:mm')}
                  size='x50'
                  type='text'
                  placeholder='Timestamp'
                />
                <Input
                  readOnly
                  value={formData.placement_external_id}
                  size='x50'
                  type='text'
                  placeholder='Placement ID'
                />
                <Input
                  readOnly
                  value={formData.notes}
                  size='x50'
                  type='text'
                  placeholder='Errors/Notes'
                />
              </div>
              {/*{(formData?.document_status === 'done' ||*/}
              {/*  formData?.document_status === 'error') && (*/}
              {/*  <div className={css.pdf}>*/}
              {/*    <Button*/}
              {/*      title='Generate PDFs Documents'*/}
              {/*      type={*/}
              {/*        !checked.applicant && !checked.client*/}
              {/*          ? 'disabled'*/}
              {/*          : 'secondary'*/}
              {/*      }*/}
              {/*      disabled={!checked.applicant && !checked.client}*/}
              {/*      onClick={handleGeneratePDF}*/}
              {/*    />*/}

              {/*    <label className={css.label}>*/}
              {/*      <input*/}
              {/*        name='applicant'*/}
              {/*        className={css.checkbox}*/}
              {/*        type='checkbox'*/}
              {/*        checked={checked.applicant}*/}
              {/*        onChange={handleCheck}*/}
              {/*      />*/}
              {/*      Applicant*/}
              {/*    </label>*/}
              {/*    <label className={css.label}>*/}
              {/*      <input*/}
              {/*        name='client'*/}
              {/*        className={css.checkbox}*/}
              {/*        type='checkbox'*/}
              {/*        checked={checked.client}*/}
              {/*        onChange={handleCheck}*/}
              {/*      />*/}
              {/*      Client*/}
              {/*    </label>*/}
              {/*  </div>*/}
              {/*)}*/}
            </>
          )}
        </main>
      )}
      <SendEmailModal
        showEmailModal={showEmailModal}
        onCloseModal={handleSendEmailModal}
        onSendEmail={handleSendEmail}
        emailData={emailData}
        formDataId={formData.id}
        onGeneratePdf={handleGeneratePDF} />
    </main>
  );
};

export default BookingFormPage;
