import css from './InsertBookingRequest.module.css';
import closeButton from '../../../../assets/images/close-button.png';
import sortUp from '../../../../assets/images/sortUp.svg';
import sortDown from '../../../../assets/images/sortDown.svg';
import checkIcon from '../../../../assets/images/checkIcon.png';
import plusBlueIcon from '../../../../assets/images/plusBlueIcon.png';
import trashIcon from '../../../../assets/images/trashIcon.png';
import DatePicker from 'react-datepicker';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { isUndefined } from 'lodash';
import { useDispatch } from 'react-redux';
import Input from '../../../../components/Input/Input';
import { saveDatesForBooking } from '../../../../features/bookingForm/bookingFormSlice';
import { useNavigate } from 'react-router-dom';
import inputCss from '../../../../components/Input/Input.module.css';
import { IMaskInput } from 'react-imask';
import api, { CALCULATE_RANGE_DATES } from '../../../../config/api';

const momentFormat = 'HH:mm';
const SHIFT_TYPES = [
  {
    'external_id': 30,
    'name': 'Day Shift',
    'overtime_rate_external_id': 796,
  },
  {
    'external_id': 28,
    'name': 'Night Shift',
    'overtime_rate_external_id': 797,
  },
  {
    'external_id': 39,
    'name': 'On Call (12pm/24h)',
    'overtime_rate_external_id': 801,
  },
  {
    'external_id': 43,
    'name': 'Overtime',
    'overtime_rate_external_id': 49,
  },
  {
    'external_id': 40,
    'name': 'Saturday',
    'overtime_rate_external_id': 800,
  },
  {
    'external_id': 44,
    'name': 'Saturday / Night Shift',
    'overtime_rate_external_id': 822,
  },
  {
    'external_id': 41,
    'name': 'Sunday & BH',
    'overtime_rate_external_id': 798,
  },
];

const selectRepeatEventData = [
  { id: 'daily', name: 'Daily' },
  { id: 'weekly', name: 'Weekly' },
  { id: 'monthly', name: 'Monthly' },
  { id: 'yearly', name: 'Yearly' },
];

export const InsertBookingRequest = ({
                                       onCloseInsertBooking,
                                       applicantDetailId,
                                     }) => {
    // const { shift_types } = useSelector(
    //   state => state.referenceBook.bookData,
    // );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [repeat, setRepeat] = useState(false);
    const [isOpenShiftSelect, setIsOpenShiftSelect] = useState(false);
    const [isOpenRepeatSelect, setIsOpenRepeatSelect] = useState(false);
    const [selectedRepeatType, setSelectedRepeatType] = useState('daily');
    const [myEventData, setMyEventData] = useState([]);
    const [myRangeData, setMyRangeData] = useState({});
    const [eventDataLocal, setEventDataLocal] = useState({});
    const [counterRepeat, setCounterRepeat] = useState(1);
    const [daysData, setDaysData] = useState([
      { id: 1, name: 'Mon', checked: false },
      { id: 2, name: 'Tue', checked: false },
      { id: 3, name: 'Wed', checked: false },
      { id: 4, name: 'Thu', checked: false },
      { id: 5, name: 'Fri', checked: false },
      { id: 6, name: 'Sat', checked: false },
      { id: 7, name: 'Sun', checked: false },
    ]);

    const handleChangeDaysSelect = (dayId) => {
      setDaysData(daysData.map(day =>
        day.id === dayId ? { ...day, checked: !day.checked } : day,
      ));
    };

    useEffect(() => {
      let currentDayNumber = moment().day();
      currentDayNumber = currentDayNumber === 0 ? 7 : currentDayNumber;
      handleChangeDaysSelect(currentDayNumber);
    }, []);


    useEffect(() => {
      if (myEventData.length === 0) {
        setRepeat(false);
        const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');
        let formattedStartDate = moment(formattedDate).set({
          hour: 7,
          minute: 0,
          second: 0,
        }).format('YYYY-MM-DD HH:mm:ss');
        let formattedEndDate = moment(formattedDate).set({
          hour: 19,
          minute: 0,
          second: 0,
        }).format('YYYY-MM-DD HH:mm:ss');
        setMyEventData([{
          'name': `Ad Hoc 1`,
          'shift_external_id': 30,
          'title': 'Day Shift',
          'start': formattedStartDate,
          'start_time': formattedStartDate,
          'end': formattedEndDate,
          'end_time': formattedEndDate,
          'date': formattedDate,
          'purchase_order': '',
          'hours': 8,
          'ward': '',
        }]);
        setMyRangeData({
          'shift_external_id': 30,
          'title': 'Day Shift',
          'start': formattedStartDate,
          'date_from': formattedStartDate,
          'end': formattedEndDate,
          'date_to': formattedEndDate,
          'date': formattedDate,
          'purchase_order': '',
          'hours': 8,
          'ward': '',
          'repeat_by': 'daily',
          'repeat_every': 1,
        });
      }
    }, [eventDataLocal]);


    const handleAddAdHoc = async () => {
      const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');
      let formattedStartDate = moment(formattedDate).set({
        hour: 7,
        minute: 0,
        second: 0,
      }).format('YYYY-MM-DD HH:mm:ss');
      let formattedEndDate = moment(formattedDate).set({
        hour: 19,
        minute: 0,
        second: 0,
      }).format('YYYY-MM-DD HH:mm:ss');
      const newAdHoc = {
        'name': `Ad Hoc ${myEventData.length + 1}`,
        'shift_external_id': 30,
        'title': 'Day Shift',
        'start': formattedStartDate,
        'start_time': formattedStartDate,
        'end': formattedEndDate,
        'end_time': formattedEndDate,
        'date': formattedDate,
        'purchase_order': '',
        'hours': 8,
        'ward': '',
      };
      setMyEventData([...myEventData, newAdHoc]);
    };

    const handleRemoveAdHoc = (nameToRemove) => {
      const updatedEvents = myEventData.filter(event => event.name !== nameToRemove);
      setMyEventData(updatedEvents);
    };

    const formatStringDate = (strDate) => {
      const formattedDate = moment(strDate, 'YYYY-MM-DD HH:mm:ss');
      return new Date(formattedDate.format());
    };

    const formatStringTime = (strDate) => {
      return moment(strDate).format('HH:mm');
    };


    const handleChangeShiftType = (name, shiftType) => {
      const newAdHocData = myEventData.map(adHoc => {
        if (adHoc.name === name) {
          let updates = {
            ...adHoc,
            shift_external_id: shiftType.external_id,
          };
          let formattedStartDate = moment(adHoc.start_time);
          let formattedEndDate = moment(adHoc.end_time);
          let formattedHoursDate = moment(adHoc.hours);
          if (shiftType.external_id === 30 || shiftType.external_id === 43 || shiftType.external_id === 40 || shiftType.external_id === 41) {
            formattedStartDate = moment(adHoc.start_time).set({
              hour: 7,
              minute: 0,
              second: 0,
            }).format('YYYY-MM-DD HH:mm:ss');
            formattedEndDate = moment(adHoc.end_time).set({
              hour: 19,
              minute: 0,
              second: 0,
            }).format('YYYY-MM-DD HH:mm:ss');
            formattedHoursDate = 12;
          }
          if (shiftType.external_id === 28 || shiftType.external_id === 44) {
            formattedStartDate = moment(adHoc.start_time).set({
              hour: 19,
              minute: 0,
              second: 0,
            }).format('YYYY-MM-DD HH:mm:ss');
            formattedEndDate = moment(adHoc.end_time).set({
              hour: 7,
              minute: 0,
              second: 0,
            }).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
            formattedHoursDate = 12;
          }
          if (shiftType.external_id === 39) {
            formattedStartDate = moment(adHoc.start_time).set({
              hour: 0,
              minute: 0,
              second: 0,
            }).format('YYYY-MM-DD HH:mm:ss');
            formattedEndDate = moment(adHoc.end_time).set({
              hour: 0,
              minute: 0,
              second: 0,
            }).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
            formattedHoursDate = 24;
          }
          return {
            ...updates,
            start_time: formattedStartDate,
            start: formattedStartDate,
            end_time: formattedEndDate,
            end: formattedEndDate,
            hours: formattedHoursDate,
          };
        }
        return adHoc;
      });
      setMyEventData(newAdHocData);
      setIsOpenShiftSelect(undefined);
    };


    const handleChangeRangeShiftType = (shiftType) => {
      let formattedStartDate = moment(myRangeData.start_time);
      let formattedEndDate = moment(myRangeData.end_time);
      let formattedHoursDate = moment(myRangeData.hours);
      if (shiftType.external_id === 30 || shiftType.external_id === 43 || shiftType.external_id === 40 || shiftType.external_id === 41) {
        formattedStartDate = moment(myRangeData.start_time).set({
          hour: 7,
          minute: 0,
          second: 0,
        }).format('YYYY-MM-DD HH:mm:ss');
        formattedEndDate = moment(myRangeData.end_time).set({
          hour: 19,
          minute: 0,
          second: 0,
        }).format('YYYY-MM-DD HH:mm:ss');
        formattedHoursDate = 12;
      }
      if (shiftType.external_id === 28 || shiftType.external_id === 44) {
        formattedStartDate = moment(myRangeData.start_time).set({
          hour: 19,
          minute: 0,
          second: 0,
        }).format('YYYY-MM-DD HH:mm:ss');
        formattedEndDate = moment(myRangeData.end_time).set({
          hour: 7,
          minute: 0,
          second: 0,
        }).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
        formattedHoursDate = 12;
      }
      if (shiftType.external_id === 39) {
        formattedStartDate = moment(myRangeData.start_time).set({
          hour: 0,
          minute: 0,
          second: 0,
        }).format('YYYY-MM-DD HH:mm:ss');
        formattedEndDate = moment(myRangeData.end_time).set({
          hour: 0,
          minute: 0,
          second: 0,
        }).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
        formattedHoursDate = 24;
      }
      setMyRangeData({
        ...myRangeData,
        start_time: formattedStartDate,
        start: formattedStartDate,
        end_time: formattedEndDate,
        end: formattedEndDate,
        hours: formattedHoursDate,
        shift_external_id: shiftType.external_id,
      });
      setIsOpenShiftSelect(null);
    };

    const handleSaveRepeatEvent = async () => {
      const checkedIds = daysData.filter(el => el.checked === true).map(el => el.id);
      const { data } = await api.CALCULATE_RANGE_DATES({
        'date_from': myRangeData.date_from.split(' ')[0],
        'date_to': myRangeData.date_to.split(' ')[0],
        'repeat_by': selectedRepeatType,
        'repeat_every': counterRepeat,
        'repeat_days': checkedIds,
      });
      if (data) {
        let resultRangeData = [];
        (data || []).map((day) => {
          const startTime = myRangeData.start.split(' ')[1];
          const endTime = myRangeData.end.split(' ')[1];
          resultRangeData.push({
            ...myRangeData,
            hours: myRangeData.hours,
            date: convertDate(day),
            start: convertDate(day + ' ' + startTime),
            start_time: convertDate(day + ' ' + startTime),
            end: convertDate(day + ' ' + endTime),
            end_time: convertDate(day + ' ' + endTime),
          });
        });
        console.log(resultRangeData);
        navigate(`/booking-form-applicant/${applicantDetailId}`, { replace: true });
        dispatch(saveDatesForBooking(resultRangeData));
      }
    };

    const handleChangeStartDate = (date, name) => {
      const momentDate = moment(new Date(date));
      const formattedDate = momentDate.format('YYYY-MM-DD HH:mm:ss');
      const newAdHocData = myEventData.map(adHoc => {
        if (adHoc.name === name) {
          return {
            ...adHoc,
            date: formattedDate,
            start_time: formattedDate,
            start: formattedDate,
            end_time: formattedDate,
            end: formattedDate,
          };
        }
        return adHoc;
      });
      setMyEventData(newAdHocData);
    };


    const handleChangeStartTime = (time, name) => {
      const newAdHocData = myEventData.map(adHoc => {
        if (adHoc.name === name) {
          const [datePart] = adHoc.start_time.split(' ');
          const newStartTime = `${datePart} ${time}`;
          return { ...adHoc, start_time: newStartTime, start: newStartTime };
        }
        return adHoc;
      });
      setMyEventData(newAdHocData);
    };

    function formatTimeHHMM(time) {
      if (!time || time.length !== 4) {
        return null;
      }
      const hours = time.substring(0, 2);
      const minutes = time.substring(2, 4);
      return `${hours}:${minutes}`; // Перетворюємо в "HH:mm"
    }

    const handleChangeHours = (name, time) => {
      const formattedTime = formatTimeHHMM(time);
      const newAdHocData = myEventData.map(adHoc => {
        if (adHoc.name === name) {
          let newEndTime;
          if (formattedTime) {
            newEndTime = moment(adHoc.start_time, 'YYYY-MM-DD HH:mm:ss').add(moment.duration(`PT${formattedTime.split(':').join('H')}M`));
          } else {
            newEndTime = moment(adHoc.end_time);
          }

          return {
            ...adHoc,
            hours: time,
            end_time: newEndTime.format('YYYY-MM-DD HH:mm:ss'),
            end: newEndTime.format('YYYY-MM-DD HH:mm:ss'),
          };
        }
        return adHoc;
      });
      setMyEventData(newAdHocData);
    };

    const handleChangeRangeHours = (time) => {
      const formattedTime = formatTimeHHMM(time);
      let newEndTime;
      if (formattedTime) {
        newEndTime = moment(myRangeData.start, 'YYYY-MM-DD HH:mm:ss').add(moment.duration(`PT${formattedTime.split(':').join('H')}M`));
      } else {
        newEndTime = moment(myRangeData.end);
      }

      setMyRangeData({
        ...myRangeData,
        hours: time,
        end: newEndTime.format('YYYY-MM-DD HH:mm:ss'),
      });
    };

    const handleChangePurchaseOrder = (e, name) => {
      const newAdHocData = myEventData.map(adHoc => {
        if (adHoc.name === name) {
          return { ...adHoc, purchase_order: e.target.value };
        }
        return adHoc;
      });
      setMyEventData(newAdHocData);
    };

    const handleChangeWard = (e, name) => {
      const newAdHocData = myEventData.map(adHoc => {
        if (adHoc.name === name) {
          return { ...adHoc, ward: e.target.value };
        }
        return adHoc;
      });
      setMyEventData(newAdHocData);
    };

    const convertDate = (date) => {
      const parsedDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
      return parsedDate.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    };

    const handleSaveAdHoc = () => {
      const newEvents = [];
      myEventData.map((event) => {
        newEvents.push({
          ...event,
          hours: event.hours,
          date: convertDate(event.date),
          start: convertDate(event.start),
          start_time: convertDate(event.start_time),
          end: convertDate(event.end),
          end_time: convertDate(event.end_time),
        });
      });
      console.log(newEvents)
      console.log('newEvents')
      navigate(`/booking-form-applicant/${applicantDetailId}`, { replace: true });
      dispatch(saveDatesForBooking(newEvents));
    };

    const selectedShiftTypeRange = SHIFT_TYPES.find(eventType => eventType.external_id === myRangeData.shift_external_id);
    const repeatTypeObject = selectRepeatEventData.find(eventType => eventType.id === selectedRepeatType);
    const repeatTypeName = repeatTypeObject ? repeatTypeObject.name : '';

    return (
      <div
        style={{ left: '198px' }}
        className={css.modalContent}>

        <div className={css.headerContainer}>
          <span>{'Insert Booking Request'}</span>
          <img src={closeButton} alt={'closeButton'} className={css.closeButton} onClick={onCloseInsertBooking} />
        </div>
        <div className={css.contentContainer}>
          <div className={css.buttonsContainer}>
            <div
              className={css.buttonSwitchContainer}
              style={{ backgroundColor: !repeat ? '#0CADD3' : 'white' }}
              onClick={() => {
                setRepeat(false);
              }}>
              <span className={css.buttonSwitchText} style={{ color: !repeat ? '#ffffff' : '#2E2E30' }}>Ad Hoc</span>
            </div>
            <div
              className={css.buttonSwitchContainer}
              style={{ backgroundColor: !repeat ? 'white' : '#0CADD3' }}
              onClick={() => {
                setRepeat(true);
              }}>
              <span className={css.buttonSwitchText} style={{ color: !repeat ? '#2E2E30' : '#FFFFFF' }}>Repeat</span>
            </div>
          </div>
          {!repeat &&
            <div>
              {myEventData.map((adHoc, indexAd) => {
                const selectedShiftType = SHIFT_TYPES.find(eventType => eventType.external_id === adHoc.shift_external_id);
                return (
                  <div className={css.adHocContainer}>
                    <span className={css.adHocName}>{adHoc?.name ?? ''}</span>
                    {indexAd !== 0 &&
                      <div className={css.trashIconContainer} onClick={() => {
                        handleRemoveAdHoc(adHoc.name);
                      }}>
                        <img src={trashIcon} alt={'trashIcon'} className={css.trashIcon} />
                      </div>
                    }
                    <span className={css.typeDateText}>Shifts & Date</span>
                    <div className={css.firsRowContainer1}>
                      <div style={{ marginTop: '16px', marginRight: '10px' }}>
                        <span className={css.titleText}>Date</span>
                        <div
                          className={css.dateSelectContainer}
                          style={{ width: '169px' }}>
                          <DatePicker
                            locale='en'
                            className={css.inputDate}
                            dateFormat='dd/MM/yyyy'
                            onChange={(e) => {
                              handleChangeStartDate(e, adHoc.name);
                            }}
                            selected={(adHoc.date && new Date(formatStringDate(adHoc.date))) || ''}
                          />
                        </div>
                      </div>
                      <div className={css.eventTypeContainer}>
                        <span className={css.titleText}>Shift</span>
                        <div
                          style={{ opacity: '1' }}
                          className={css.eventTypeSelectValue}
                          onClick={() => {
                            if (isOpenShiftSelect === adHoc.name) {
                              setIsOpenShiftSelect(null);
                            } else {
                              setIsOpenShiftSelect(adHoc.name);
                            }
                          }}>
                          {selectedShiftType ?
                            <span className={css.selectedEvent}>{selectedShiftType?.name ?? ''}</span>
                            :
                            <span className={css.selectedEventPlaceholder}>Select Type</span>
                          }
                          <img
                            alt='sorting way'
                            className={css.selectIcon}
                            src={isOpenShiftSelect ? sortUp : sortDown}
                          />
                        </div>
                        {isOpenShiftSelect === adHoc.name &&
                          <div className={css.selectItemsContainer}>
                            {SHIFT_TYPES.map((shiftType) => (
                              <div
                                className={css.eventValuesContainer}
                                onClick={() => {
                                  handleChangeShiftType(adHoc.name, shiftType);
                                  setIsOpenShiftSelect(null);
                                }}>
                              <span
                                style={{ color: '#2E2E30' }}
                                className={css.selectItemText1}>{shiftType?.name ?? ''}</span>
                              </div>
                            ))}
                          </div>
                        }
                      </div>
                    </div>
                    <div className={css.selectTimeContainer}
                         style={{ opacity: '1' }}>
                      <div className={css.timeValueContainer}>
                        <div className={css.timeSelectContainerRange}>
                          <span className={css.titleTextTime}>Start</span>
                          <input
                            disabled={adHoc.all_day}
                            type='time'
                            className={css.timeSelect}
                            value={formatStringTime(adHoc.start_time)}
                            onChange={(e) => {
                              handleChangeStartTime(e.target.value, adHoc.name);
                            }}
                          />
                        </div>
                        <div className={css.timeSelectContainerRange} style={{ marginLeft: '10px' }}>
                          <span className={css.titleTextTime}>Hours</span>
                          <IMaskInput
                            mask={'00:00'}
                            value={(() => {
                              if (adHoc?.hours) {
                                if (typeof adHoc.hours === 'number') {
                                  const value = adHoc?.hours.toString();
                                  if (value.includes('.')) {
                                    const [hours, minutes] = value.split('.');

                                    return `${+hours < 10 ? '0' + hours : hours}:${Number(
                                      (+minutes * 60) / 100,
                                    ).toFixed(0)}`;
                                  } else if (value.length === 2) {
                                    return `${value}:00`;
                                  } else return value;
                                } else return adHoc?.hours.toString();
                              } else return '';
                            })()}
                            style={{ border: 'none', height: '20px', width: '60px' }}
                            unmask={true}
                            onAccept={(value, mask) => {
                              handleChangeHours(adHoc.name, value);
                            }}
                            onBlur={e => {
                              const value = moment(e.target.value, momentFormat).format(
                                momentFormat,
                              );
                              handleChangeHours(adHoc.name, value === 'Invalid date' ? '' : value === '00:00' ? '24:00' : value);
                            }}
                            placeholder='8'
                            className={inputCss.input}
                          />
                        </div>
                        <div className={css.timeSelectContainerRange} style={{ marginLeft: '10px' }}>
                          <span className={css.titleTextTime}>End</span>
                          <input
                            type='time'
                            disabled={true}
                            className={css.timeSelect}
                            value={formatStringTime(adHoc.end_time)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={css.fullWidth}>
                      <div className={css.titleTextContainer}>
                        <span className={css.titleText}>Purchase Order</span>
                      </div>
                      <Input
                        withoutAdaptivePlaceholder
                        name='first_name'
                        value={adHoc.purchase_order}
                        size='x100'
                        type='text'
                        placeholder='Enter Purchase Order'
                        onChange={(e) => {
                          handleChangePurchaseOrder(e, adHoc.name);
                        }}
                      />
                    </div>
                    <div className={css.fullWidth}>
                      <div className={css.titleTextContainer}>
                        <span className={css.titleText}>Ward</span>
                      </div>
                      <Input
                        withoutAdaptivePlaceholder
                        name='first_name'
                        value={adHoc.ward}
                        size='x100'
                        type='text'
                        placeholder='Enter Ward'
                        onChange={(e) => {
                          handleChangeWard(e, adHoc.name);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              <div className={css.rowContainer} onClick={handleAddAdHoc}>
                <img src={plusBlueIcon} className={css.plusIcon} alt={'plusBlueIcon'} />
                <span className={css.addDateText}>Add Date</span>
              </div>
              <div className={css.buttonsContainer1}>
                <div className={css.cancelButton} onClick={onCloseInsertBooking}>
                  <span style={{ color: '#0E7193' }}>Cancel</span>
                </div>
                <div className={css.saveButton} onClick={async () => {
                  await handleSaveAdHoc();
                }}>
                  <span style={{ color: 'white' }}>Insert</span>
                </div>
              </div>
            </div>
          }
          {repeat &&
            <>
              <div className={css.adHocContainer}>
                <span className={css.typeDateText}>Shifts & Date</span>
                <div className={css.eventTypeContainer}>
                  <span className={css.titleText}>Shift</span>
                  <div
                    className={css.eventTypeSelectValue}
                    onClick={() => {
                      if (isOpenShiftSelect === myRangeData.name) {
                        setIsOpenShiftSelect(null);
                      } else {
                        setIsOpenShiftSelect(myRangeData.name);
                      }
                    }}>
                    {selectedShiftTypeRange ?
                      <span className={css.selectedEvent}>{selectedShiftTypeRange?.name ?? ''}</span>
                      :
                      <span className={css.selectedEventPlaceholder}>Select Type</span>
                    }
                    <img
                      alt='sorting way'
                      className={css.selectIcon}
                      src={isOpenShiftSelect ? sortUp : sortDown}
                    />
                  </div>
                  {isOpenShiftSelect === myRangeData.name &&
                    <div className={css.selectItemsContainer}>
                      {SHIFT_TYPES.map((shiftType) => (
                        <div
                          className={css.eventValuesContainer}
                          onClick={() => {
                            handleChangeRangeShiftType(shiftType);
                          }}>
                              <span
                                style={{ color: '#2E2E30' }}
                                className={css.selectItemText1}>{shiftType?.name ?? ''}</span>
                        </div>
                      ))}
                    </div>
                  }
                </div>
                <div className={css.firsRowContainer1} style={{ marginTop: '16px' }}>
                  <div style={{ marginRight: '10px' }}>
                    <span className={css.titleText}>From</span>
                    {isOpenShiftSelect !== myRangeData.name &&
                      <div
                        className={css.dateSelectContainer}
                        style={{ width: '190px' }}>
                        <DatePicker
                          locale='en'
                          className={css.inputDate}
                          dateFormat='dd/MM/yyyy'
                          onChange={(e) => {
                            const momentDate = moment(new Date(e));
                            const formattedDate = momentDate.format('YYYY-MM-DD HH:mm:ss');
                            setMyRangeData({ ...myRangeData, date_from: formattedDate});
                          }}
                          selected={(myRangeData.date_from && new Date(formatStringDate(myRangeData.date_from))) || ''}
                        />
                      </div>
                    }
                  </div>
                  <div>
                    <span className={css.titleText}>To</span>
                    {isOpenShiftSelect !== myRangeData.name &&
                      <div
                        className={css.dateSelectContainer}
                        style={{ width: '190px' }}>
                        <DatePicker
                          locale='en'
                          className={css.inputDate}
                          dateFormat='dd/MM/yyyy'
                          onChange={(e) => {
                            const momentDate = moment(new Date(e));
                            const formattedDate = momentDate.format('YYYY-MM-DD HH:mm:ss');
                            setMyRangeData({ ...myRangeData, date_to: formattedDate });
                          }}
                          selected={(myRangeData.date_to && new Date(formatStringDate(myRangeData.date_to))) || ''}
                        />
                      </div>
                    }
                  </div>
                </div>
                <div className={css.selectTimeContainer}
                     style={{ opacity: '1' }}>
                  <div className={css.timeValueContainer}>
                    <div className={css.timeSelectContainerRange}>
                      <span className={css.titleTextTime}>Start</span>
                      <input
                        type='time'
                        className={css.timeSelect}
                        value={formatStringTime(myRangeData.start)}
                        onChange={(e) => {
                          const [datePart] = myRangeData.start.split(' ');
                          const newStartTime = `${datePart} ${e.target.value}`;

                          const durationHours = parseInt(myRangeData.hours, 10);
                          const endTime = moment(newStartTime).add(durationHours, 'hours').format('YYYY-MM-DD HH:mm:ss');

                          setMyRangeData({
                            ...myRangeData,
                            start: newStartTime,
                            end: endTime,
                          });
                        }}
                      />
                    </div>
                    <div className={css.timeSelectContainerRange} style={{ marginLeft: '10px' }}>
                      <span className={css.titleTextTime}>Hours</span>
                      <IMaskInput
                        mask={'00:00'}
                        value={(() => {
                          if (myRangeData?.hours) {
                            if (typeof myRangeData.hours === 'number') {
                              const value = myRangeData?.hours.toString();
                              if (value.includes('.')) {
                                const [hours, minutes] = value.split('.');

                                return `${+hours < 10 ? '0' + hours : hours}:${Number(
                                  (+minutes * 60) / 100,
                                ).toFixed(0)}`;
                              } else if (value.length === 2) {
                                return `${value}:00`;
                              } else return value;
                            } else return myRangeData?.hours.toString();
                          } else return '';
                        })()}
                        style={{ border: 'none', height: '20px', width: '60px' }}
                        unmask={true}
                        onAccept={(value, mask) => {
                          handleChangeRangeHours(value);
                        }}
                        onBlur={e => {
                          const value = moment(e.target.value, momentFormat).format(
                            momentFormat,
                          );
                          handleChangeRangeHours(value === 'Invalid date' ? '' : value === '00:00' ? '24:00' : value);
                        }}
                        placeholder='8'
                        className={inputCss.input}
                      />
                    </div>
                    <div className={css.timeSelectContainerRange} style={{ marginLeft: '10px' }}>
                      <span className={css.titleTextTime}>End</span>
                      <input
                        type='time'
                        disabled={true}
                        className={css.timeSelect}
                        value={formatStringTime(myRangeData.end)}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.fullWidth}>
                  <div className={css.titleTextContainer}>
                    <span className={css.titleText}>Purchase Order</span>
                  </div>
                  <Input
                    withoutAdaptivePlaceholder
                    name='purchase_order'
                    value={myRangeData.purchase_order}
                    size='x100'
                    type='text'
                    placeholder='Enter Purchase Order'
                    onChange={(e) => {
                      setMyRangeData({...myRangeData, purchase_order: e.target.value});
                    }}
                  />
                </div>
                <div className={css.fullWidth}>
                  <div className={css.titleTextContainer}>
                    <span className={css.titleText}>Ward</span>
                  </div>
                  <Input
                    withoutAdaptivePlaceholder
                    name='first_name'
                    value={myRangeData.ward}
                    size='x100'
                    type='text'
                    placeholder='Enter Ward'
                    onChange={(e) => {
                      setMyRangeData({...myRangeData, ward: e.target.value});
                    }}
                  />
                </div>
                <div className={css.repeatText}>
                  <span>Repeat</span>
                </div>

                <div className={css.firsRowContainer}>
                  <div>
                    <span className={css.titleText}>Repeat by</span>
                    <div className={css.eventTypeContainer1}
                         style={{ zIndex: 0 }}>
                      <div
                        className={css.eventTypeSelectValue}
                        onClick={() => {
                          setIsOpenRepeatSelect(!isOpenRepeatSelect);
                        }}>
                        <span className={css.selectedEvent}>{repeatTypeName}</span>
                        <img
                          alt='sorting way'
                          className={css.selectIcon}
                          src={isOpenRepeatSelect ? sortUp : sortDown}
                        />
                      </div>
                      {isOpenRepeatSelect &&
                        <div className={css.selectItemsContainer1}>
                          {selectRepeatEventData.map((eventType) => (
                            <div
                              className={css.eventValuesContainer}
                              onClick={() => {
                                setSelectedRepeatType(eventType.id);
                                setIsOpenRepeatSelect(false);
                              }}>
                                  <span
                                    style={{ color: selectedRepeatType === eventType.id ? '#0CADD3' : 'black' }}
                                    className={css.selectItemText}>
                                    {eventType?.name ?? ''}
                                  </span>
                              {selectedRepeatType === eventType.id &&
                                <img src={checkIcon} alt={'checkIcon'} className={css.checkIcon} />
                              }
                            </div>
                          ))}
                        </div>
                      }
                    </div>
                  </div>
                  <div className={css.repeatEveryContainer}>
                    <span className={css.titleText}>Repeat Every</span>
                    <div className={css.repeatEveryItem}>
                      <div className={css.repeatTextContainer}>
                        <input
                          type='number'
                          min='1'
                          max='1000'
                          value={counterRepeat}
                          className={css.inputText}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10);
                            if (value < 1) {
                              setCounterRepeat(1);
                            } else if (value > 999) {
                              setCounterRepeat(999);
                            } else if (isNaN(value)) {
                              setCounterRepeat(1);
                            } else {
                              setCounterRepeat(value);
                            }
                          }} />
                        <span className={css.repeatName}>
                          {(repeatTypeName === 'Daily' ? 'Day' : repeatTypeName).replace('ly', '')}
                        </span>
                      </div>
                      <div className={css.rowContainer}>
                        <div
                          className={`${css.openNoteContainer} ${counterRepeat === 999 ? css.disableButton : {}}}`}
                          onClick={() => {
                            if (counterRepeat !== 999) {
                              setCounterRepeat(counterRepeat + 1);
                            }
                          }}>
                          <span className={css.openNoteText}>+</span>
                        </div>
                        <div
                          className={`${css.openNoteContainer} ${counterRepeat === 1 ? css.disableButton : {}}`}
                          onClick={() => {
                            if (counterRepeat !== 1) {
                              setCounterRepeat(counterRepeat - 1);
                            }
                          }}>
                          <span className={css.openNoteText}>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {selectedRepeatType === 'weekly' &&
                  <div style={{ marginTop: '16px' }}>
                    <div>
                      <span className={css.titleText}>Repeat by</span>
                    </div>
                    <div className={css.daysContainer}>
                      {daysData.map((day) => (
                        <div
                          className={`${css.dayItem} ${day.checked ? css.dayItemChecked : ''}`}
                          onClick={() => {
                            handleChangeDaysSelect(day.id);
                          }}>
                          <span className={`${css.dayText} ${day.checked ? css.dayTextChecked : ''}`}>{day.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                }
              </div>

              <div className={css.buttonsContainer1}>
                <div className={css.cancelButton} onClick={onCloseInsertBooking}>
                  <span style={{ color: '#0E7193' }}>Cancel</span>
                </div>
                <div className={css.saveButton} onClick={async () => {
                  await handleSaveRepeatEvent();
                }}>
                  <span style={{ color: 'white' }}>Insert</span>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    );
  }
;
