import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import {
  addJob,
  changeType,
  changeStartDate,
  changeEndDate,
  changePeriod,
  setNewNotes,
  setNewFiles,
} from '../../features/bookingForm/bookingFormSlice';
import Standard from './Standard/Standard';
import AdHoc from './AdHoc/AdHoc';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Select from '../Select/Select';
import vectorDown from '../../assets/images/vectorDown.png';
import vectorUp from '../../assets/images/vectorUp.png';
import css from './JobSection.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import Editor from '../Editor/Editor';
import word from '../../assets/images/word.png';
import api from '../../config/api';

const options = [
  {
    key: 'default',
    title: 'Choose period',
  },
  {
    key: 'month',
    title: 'Month',
  },
  {
    key: 'quarter',
    title: 'Quarter',
  },
  {
    key: 'year',
    title: 'Year',
  },
];

const JobSection = ({ id, isAvailability }) => {
  const [isJob, setIsJob] = useState(false);

  const filesFromStore = useSelector(state => state.bookingForm.files);
  const notesFromStore = useSelector(state => state.bookingForm.notes);
  const [noteText, setNoteText] = useState(notesFromStore || notesFromStore);
  const [files, setFiles] = useState(filesFromStore);
  const [showPhoto, setShowPhoto] = useState(false);

  const job = useSelector(state => state.bookingForm.formData.job);
  const [isAgree, setIsAgree] = useState(job?.is_agree ?? null);

  const start_date = useSelector(
    state => state.bookingForm.formData.job?.start_date,
  );
  const end_date = useSelector(
    state => state.bookingForm.formData.job?.end_date,
  );
  const type = useSelector(
    state => state.bookingForm.formData.job?.booked_data.type,
  );
  const period = useSelector(
    state => state.bookingForm.formData.job?.booked_data.period,
  );
  const dates = useSelector(
    state => state.bookingForm.formData.job?.booked_data.dates,
  );
  const day_shifts = useSelector(
    state => state.bookingForm.formData.job?.booked_data.day_shifts,
  );
  const outside_ir35 = useSelector(
    state => state.bookingForm.formData.client?.outside_ir35,
  );
  const { employment_types, job_pay_types } = useSelector(
    state => state.referenceBook.bookData,
  );
  const formData = useSelector(state => state.bookingForm.formData);
  const dispatch = useDispatch();

  const handleSectionShowAndHide = () => setIsJob(!isJob);

  const handleTabToShow = (data) =>
    type !== data && dispatch(changeType(data));

  const handleStartDate = date => {
    dispatch(changeStartDate(date));
    dispatch(changePeriod('default'));
  };

  const handleChangeIsAgree = (isAgreeNew) => {
    setIsAgree(isAgreeNew)
    dispatch(addJob({...job, is_agree: isAgreeNew}));
  };

  const handleEndDate = date => {
    dispatch(changeEndDate(date));
    dispatch(changePeriod('default'));
  };

  const handleOptionChange = e => {
    dispatch(changeStartDate(null));
    dispatch(changeEndDate(null));
    dispatch(changePeriod(e.target.value));
  };

  const handleChange = e => {
    if (e.target.name === 'placement_purchase_order') {
      if (e.target.value.length > 20) return;
    }
    if (
      e.target.name === 'position_id' ||
      e.target.name === 'specialty_id' ||
      e.target.name === 'grade_id'
    ) {
      if (e.target.name === 'position_id') {
        dispatch(
          addJob({
            ...job,
            [e.target.name]: e.target.value,
            title: `${
              job?.positions?.find(el => el.id === Number(e.target.value))
                ?.name || ''
            }: ${
              job?.specialties?.find(el => el.id === Number(job.specialty_id))
                ?.name || ''
            } (${
              job?.grades?.find(el => el.id === Number(job.grade_id))?.name ||
              ''
            })`,
          }),
        );

        return;
      }
      if (e.target.name === 'specialty_id') {
        dispatch(
          addJob({
            ...job,
            [e.target.name]: e.target.value,
            title: `${
              job?.positions?.find(el => el.id === Number(job.position_id))
                ?.name || ''
            }: ${
              job?.specialties?.find(el => el.id === Number(e.target.value))
                ?.name || ''
            } (${
              job?.grades?.find(el => el.id === Number(job.grade_id))?.name ||
              ''
            })`,
          }),
        );

        return;
      }
      if (e.target.name === 'grade_id') {
        dispatch(
          addJob({
            ...job,
            [e.target.name]: e.target.value,
            title: `${
              job?.positions?.find(el => el.id === Number(job.position_id))
                ?.name || ''
            }: ${
              job?.specialties?.find(el => el.id === Number(job.specialty_id))
                ?.name || ''
            } (${
              job?.grades?.find(el => el.id === Number(e.target.value))?.name ||
              ''
            })`,
          }),
        );

        return;
      }

      return;
    }
    dispatch(addJob({ ...job, [e.target.name]: e.target.value }));
  };

  const handleNoteTextChange = (value) => {
    setNoteText(value);
    dispatch(setNewNotes(value));
  };

  const handleImageToBase64 = (event) => {
    const files = event.target.files;
    let qq = [];

    const promises = Array.from(files).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = function() {
          qq.push({
            name: file.name,
            content: reader.result.split(',')[1],
          });
          resolve();
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    });

    return Promise.all(promises)
      .then(() => {
        return qq;
      })
      .catch((err) => {
        console.error(err);
      });
  };


  const handleImageChange = async (event) => {
    const base64Img = await handleImageToBase64(event);
    setFiles([...files, ...base64Img]);
    dispatch(setNewFiles([...filesFromStore, ...base64Img]));
    event.target.value = null;
  };

  const handleRemoveImage = async (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    dispatch(setNewFiles(updatedFiles));
  };

  const handleToggleModal = (photo) => {
    setShowPhoto(photo);
  };

  const handleDownloadFiles = async (file) => {
    await api.GET_FILES(!file ? files : [file]);
  };

  return (
    <section>
      <Button
        type='sectionBtn'
        style={
          isJob
            ? null
            : {
              background: 'rgba(13, 85, 130, 0.2)',
              color: 'var(--secondary)',
            }
        }
        onClick={handleSectionShowAndHide}
        title='Job section'
      >
        <img
          className={css.arrow}
          alt='arrow'
          src={isJob ? vectorUp : vectorDown}
        />
      </Button>
      <div
        className={css.section}
        style={isJob ? { display: 'flex' } : { display: 'none' }}
      >
        <div className={css.flex}>
          <Select
            name='position_id'
            value={job?.position_id || ''}
            options={job?.positions}
            size='x33'
            defaultValue='Position'
            onChange={handleChange}
            required
          />
          <Select
            name='specialty_id'
            value={job?.specialty_id || ''}
            options={job?.specialties}
            size='x33'
            defaultValue='Specialty'
            onChange={handleChange}
            required
          />
          <Select
            name='grade_id'
            value={job?.grade_id || ''}
            options={job?.grades}
            size='x33'
            defaultValue='Grade / Band'
            onChange={handleChange}
            required
          />
          <Input
            name='title'
            value={`${
              job?.positions?.find(el => el.id === Number(job.position_id))
                ?.name || ''
            }: ${
              job?.specialties?.find(el => el.id === Number(job.specialty_id))
                ?.name || ''
            } (${
              job?.grades?.find(el => el.id === Number(job.grade_id))?.name ||
              ''
            })`}
            size='x33'
            type='text'
            placeholder='Job Title'
            readOnly
            required
          />
          <Select
            name='employment_term_external_id'
            value={job?.employment_term_external_id || ''}
            options={employment_types}
            size='x33'
            defaultValue='Employment Term'
            onChange={handleChange}
            required
          />
          <Select
            name='sector_id'
            value={job?.sector_id || ''}
            options={job?.sectors}
            size='x33'
            defaultValue='Sector'
            onChange={handleChange}
            required
          />
          <Input
            name='placement_purchase_order'
            value={job?.placement_purchase_order || ''}
            size='x25'
            type='text'
            placeholder='Placement Purchase Order'
            onChange={handleChange}
            readOnly={
              !!(dates?.find(el => el.purchase_order) ||
                day_shifts?.find(el => el.purchase_order)) || formData.placement_external_id
            }
          />
          <div className={css.dateContainer}>
            {start_date && (
              <span className={css.datePlaceholder}>Start Date</span>
            )}
            <DatePicker
              locale='en'
              className={`${css.inputDate} ${start_date ? null : css.required}`}
              placeholderText='Start Date'
              dateFormat='dd/MM/yyyy'
              onChange={handleStartDate}
              selected={(start_date && new Date(start_date)) || ''}
              maxDate={(end_date && new Date(end_date)) || null}
            />
          </div>
          <div className={css.dateContainer}>
            {end_date && <span className={css.datePlaceholder}>End Date</span>}
            <DatePicker
              locale='en'
              className={`${css.inputDate} ${end_date ? null : css.required}`}
              placeholderText='End Date'
              dateFormat='dd/MM/yyyy'
              onChange={handleEndDate}
              selected={(end_date && new Date(end_date)) || ''}
              minDate={(start_date && new Date(start_date)) || null}
            />
          </div>
          <Select
            name='pay_type_external_id'
            value={job?.pay_type_external_id || ''}
            options={
              outside_ir35
                ? job_pay_types
                : job_pay_types?.filter(el => el.external_id !== 25)
            }
            size='x25'
            defaultValue='DE/SP'
            onChange={handleChange}
            required
          />
        </div>
        <div className={css.noteSection}>
          <p className={css.noteTitle}>Notes</p>
          <form>
            <Editor
              onChangeIsAgree={handleChangeIsAgree}
              isAgree={isAgree}
              disabled={formData.placement_external_id}
              myValue={noteText}
              onChangeValue={handleNoteTextChange}
              formData={formData}
              handleImageChange={handleImageChange}
              files={files}
              handleDownloadFiles={handleDownloadFiles}
            />
          </form>
          <div className={css.imageContainer}>
            {files.map((file, index) => (
              <div key={index} className={`${css.imageItem}`}>
                {file.name.endsWith('.docx') ?
                  <div>
                    <img
                      src={word}
                      alt={`Docx file ${index}`}
                      className={css.previewImage}
                      onClick={async () => {
                        await handleDownloadFiles(file);
                      }}
                    />
                    <p className={css.fileName}>{file.name}</p>
                  </div>
                  :
                  <img
                    src={file.content ? `data:image/png;base64,${file.content}` : URL.createObjectURL(file)}
                    alt={`Image ${index}`}
                    className={css.previewImage}
                    onClick={() => {
                      handleToggleModal(file);
                    }} />
                }
                {!formData.placement_external_id &&
                  <button onClick={() => handleRemoveImage(index)} className={css.deleteButton}>✖</button>
                }

              </div>
            ))}
          </div>
        </div>

        <div className={css.flexTab}>
          <button
            className={css.tab}
            onClick={handleTabToShow.bind(this, 'standard')}
            style={
              type === 'standard'
                ? {
                  color: 'var(--primary)',
                  borderBottom: '1px solid var(--primary)',
                }
                : null
            }
          >
            Standard Booking
          </button>
          <button
            className={css.tab}
            onClick={handleTabToShow.bind(this, 'ad_hoc')}
            style={
              type === 'ad_hoc'
                ? {
                  color: 'var(--primary)',
                  borderBottom: '1px solid var(--primary)',
                }
                : null
            }
          >
            Ad Hoc
          </button>
          {/* <button
            className={css.tab}
            onClick={handleTabToShow.bind(this, 'complex')}
            style={
              type === 'complex'
                ? {
                    color: 'var(--primary)',
                    borderBottom: '1px solid var(--primary)',
                  }
                : null
            }
          >
            Complex
          </button> */}
        </div>
        <Select
          size='x20'
          options={options}
          value={period || 'default'}
          onChange={handleOptionChange}
        />
        {type === 'standard' && <Standard />}
        {type === 'ad_hoc' && <AdHoc isAvailability={isAvailability}/>}
        {/* {type === 'complex' && <Complex />} */}
      </div>
      <hr
        className={css.hr}
        style={isJob ? { marginBottom: '60px' } : null}
      ></hr>
      {showPhoto &&
        <div className={css.modalOverlay}>
          <div className={css.modalContent}>
            <img
              src={showPhoto.content ? `data:image/png;base64,${showPhoto.content}` : URL.createObjectURL(showPhoto)}
              alt='Modal'
              className={css.modalImage} />
            <button
              onClick={() => {
                setShowPhoto('');
              }}
              className={css.modalCloseButton}>✖
            </button>
          </div>
        </div>
      }

    </section>
  );
};

export default JobSection;
